import { theme as baseTheme } from 'smooth-doc/src/theme'

export const theme = {
  ...baseTheme,
  fonts: {
    ...baseTheme.fonts,
    base: "'Open Sans', sans-serif",
  },
  colors: {
    ...baseTheme.colors,
    primary: '#2ea5dc',
    modes: {
      ...baseTheme.colors.modes,
      dark: {
        ...baseTheme.colors.modes.dark,
        // background: '#044458'
        // text: '#FFF',
        // primary: '#2ea5dc',
      }
    }
  },
}
